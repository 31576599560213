<template>
  <div class="disease">
    <heas title="选择疾病" />
    <div class="aa">
      <header>
        <p class="legend">请选择下方列表你要核保的疾病（最多选择五项病例）</p>
        <div class="diseaseName">
          <ul
            class="uls"
            v-if="selectedList"
          >
            <li
              class="list"
              v-for="(item,index) in selectedList"
              :key="index"
            >
              {{item.diseaseDesc.length > 6
              ? item.diseaseDesc.slice(0, 5) + "..."
              : item.diseaseDesc}}
              <p
                class="del"
                @click="delCase(item,index)"
              ></p>
            </li>
          </ul>
        </div>
        <p
          :class="selectedList.length>0?'complete':'noComplete'"
          @click="selectedOk"
        >选好了</p>
      </header>

      <div :class="selectedList.length>3?'diseaseMaxBox':selectedList.length>=1?'diseaseBox':'diseaseMinBox'">
        <ul class="diseaseUl">
          <li
            class="diseaseList"
            v-for="(item,index) in caseList"
            :key="index"
          >
            <div class="diseaseInfo">
              <p class="illnessName">{{item.caseName}}</p>
              <p
                class="dropDown"
                @click="showPrompt(item)"
              ></p>
            </div>
            <span
              v-for="(each,num) in item.caseChildList[0]"
              :key="num"
              class="heading"
            >{{each | strIntercept}}</span>
          </li>
        </ul>
      </div>
    </div>
    <van-popup
      v-model="visible"
      class="prompt-box"
      position="bottom"
      :round="true"
      :style="{ height: '80%' }"
    >
      <div class="prompt-header">
        <p class="prompt">请选择疾病（最多选择五项病例）</p>
        <p
          class="close"
          @click="hidePrompt"
        ></p>
      </div>
      <ul class="promptUl">
        <li
          class="list"
          v-for="(item,index) in selectList"
          :key="index"
        >
          <div class="selected">
            <p
              v-show="item.flag"
              class="selectedImg"
              @click="caseData(index)"
            ></p>
            <p
              v-show="!item.flag"
              class="noData"
              @click="caseData(index)"
            ></p>
          </div>
          <p class="sickness">{{item.caseChildName}}</p>
        </li>
      </ul>
      <footer>
        <p
          :class="fixFlag?'determine':'noDetermine'"
          @click="submit"
        >确定</p>
      </footer>
    </van-popup>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import eventBus from "../../utils/eventBus.js";
import Header from "@/components/Header";
import { underwriting } from "@/fetch/api/chat.js";
export default {
  data() {
    return {
      // 病例选择弹框
      visible: false,
      confirm: false,
      // 添加
      addCaseData: [],
      // 顶部名称选择
      selectedList: [],
      // 疾病列表
      caseList: [],
      // 选项疾病列表
      selectList: [],
      hebaoStatus: 0,
      fixFlag: false,
    };
  },
  filters: {
    strIntercept: function (val) {
      let reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      if (reg.test(val)) return val;
    },
  },
  mounted() {
    let nowChatList = this.nowChatList[this.nowChatList.length - 1].content
      .caseList;
    this.caseList = nowChatList;
    if (this.$route.query.code) {
      let hotCode = this.$route.query.code.split(",");
      nowChatList.forEach((item) => {
        item.caseChildList.forEach((each) => {
          each.flag = false;
          each.diseaseDesc = each.caseChildName;
          if (hotCode.indexOf(each.diseaseCode) != -1) {
            this.selectedList.push(each);
            each.flag = !each.flag;
            each.flag ? this.addCase(each) : this.delCase(each);
            this.selectList.push(item);
          }
        });
      });
    } else {
      nowChatList.forEach((item) => {
        item.caseChildList.forEach((each) => {
          each.flag = false;
        });
      });
    }
  },
  computed: {
    ...mapState({
      nowChatList: (state) => state.chat.heBaoList,
    }),
  },
  methods: {
    ...mapMutations(["SET_HeBao_LIST"]),
    //   添加、移除病例
    caseData(val) {
      this.selectList = this.selectList.map((item, index) => {
        if (
          (this.addCaseData.length < 5 && !item.flag && val == index) ||
          (this.addCaseData.length <= 5 && item.flag && val == index)
        ) {
          item.flag = !item.flag;
          item.flag ? this.addCase(item) : this.delCase(item);
        }
        return item;
      });
    },
    addCase(item) {
      this.fixFlag = true;
      this.addCaseData.push({
        diseaseCode: item.diseaseCode,
        diseaseDesc: item.caseChildName,
        flag: item.flag,
      });
    },

    delCase(item, index) {
      this.addCaseData = this.addCaseData.filter(
        (e) => e.diseaseCode != item.diseaseCode
      );
      let addCaseData = this.addCaseData.map((item) => item.diseaseCode);
      if (this.visible) {
        this.fixFlag = this.selectList.some(
          (v) => addCaseData.indexOf(v.diseaseCode) > -1
        );
      }
      if (index >= 0) {
        let selectList = [];
        this.selectedList.forEach((item) => selectList.push(item.diseaseCode));
        this.selectList = this.selectList.map((item) => {
          if (selectList.indexOf(item.diseaseCode) > -1) {
            item.flag = false;
          }
          return item;
        });
        this.caseData(index);
      }
    },
    // 确定
    submit() {
      if (this.addCaseData.length > 0) {
        this.selectedList = this.addCaseData.slice();
        this.hidePrompt();
      }
    },
    // 选好了
    selectedOk() {
      if (this.selectedList.length <= 0) {
        this.$toast("请选择疾病");
        return;
      }
      this.$router.go(-1);
      let selectedName = [];
      let selectedCode = [];
      this.selectedList.forEach((item) => selectedName.push(item.diseaseDesc));
      this.selectedList.forEach((item) => selectedCode.push(item.diseaseCode));
      eventBus.$emit("addDisease", selectedCode);
      let question = {
        broker_id: localStorage.getItem("broker_id"),
        content: selectedName.join(","),
        diseaseCode: this.selectedList,
        first_conversation: 0,
        speaker: 1,
        is_collected: "0",
        online_data_id: null,
        hebao_answer: {},
        reset_disease: 0,
        hebao: 0,
      };
      this.SET_HeBao_LIST({ type: "add", data: question });
      underwriting(question).then((res) => {
        if (res.code == 200) {
          this.hebaoStatus = res.result.content.hebao;
          this.SET_HeBao_LIST({ type: "add", data: res.result });
        }
      });
    },
    //  显示、关闭弹框
    showPrompt(item) {
      let selectList = [];
      this.selectedList.forEach((item) => selectList.push(item.diseaseCode));
      this.fixFlag = item.caseChildList.some((item) => item.flag);
      this.selectList = item.caseChildList.map((item) => {
        if (selectList.indexOf(item.diseaseCode) > -1) {
          item.flag = true;
          this.fixFlag = true;
        }
        return item;
      });
      this.visible = true;
      document.body.style.overflow = "hidden";
      var mo = function (e) {
        e.preventDefault();
      };
      document.addEventListener("touchmove", mo, false); //禁止页面滑动
    },
    hidePrompt() {
      let selectList = [];
      this.selectedList.forEach((item) => selectList.push(item.diseaseCode));
      this.addCaseData = this.addCaseData.filter(
        (e) => selectList.indexOf(e.diseaseCode) > -1
      );
      this.selectList = this.selectList.map((item) => {
        if (selectList.indexOf(item.diseaseCode) > -1) {
          item.flag = true;
        } else {
          item.flag = false;
        }
        return item;
      });
      this.visible = false;
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "";
      document.removeEventListener("touchmove", mo, false);
    },
  },
  components: {
    heas: Header,
  },
};
</script>

<style scoped lang="scss">
.disease {
  background: #f5f5f5;
  width: 100%;
  min-height: 100%;
  position: absolute;

  .header-nav {
    &::after {
      border: none;
    }
  }
  .aa {
    position: fixed;
    top: 1.17rem;
    left: 0rem;
    header {
      background: #ffffff;
      box-shadow: 0rem 0.05rem 0.4rem 0rem rgba(0, 0, 0, 0.05);
      border-radius: 0rem 0rem 0.4rem 0.4rem;
      padding: 0.2rem 0.4rem 0.45rem 0.4rem;
      z-index: 9;
      position: fixed;
      .legend {
        font-size: 0.37rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-bottom: 0.13rem;
      }
      .diseaseName {
        width: 9.2rem;
        background: #f3f3f3;
        box-shadow: 0rem -0.03rem 0.4rem 0rem rgba(0, 0, 0, 0.03);
        border-radius: 0.27rem;
        min-height: 0.91rem;
        .uls {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin: auto;
          min-height: 0.91rem;
          .list {
            width: 2.61rem;
            background: #ffffff;
            box-shadow: 0rem -0.03rem 0.4rem 0rem rgba(0, 0, 0, 0.03);
            border-radius: 0.35rem;
            font-size: 0.35rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #5b93f8;
            margin: 0.16rem 0 0.16rem 0.23rem;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            .del {
              width: 0.32rem;
              height: 0.32rem;
              position: absolute;
              right: -0.05rem;
              top: -0.05rem;
              background: url("../../assets/images/del.png") no-repeat;
              background-size: 100% 100%;
            }
          }
          .list:not(:nth-child(3n)) {
            margin-right: calc(3% / 2);
          }
        }
      }
      .complete,
      .noComplete {
        width: 3.07rem;
        height: 0.8rem;
        background: #4c7fff;
        border-radius: 0.72rem;
        font-size: 0.43rem;
        font-family: AlibabaPuHuiTiR;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.35rem auto 0 auto;
      }
      .noComplete {
        opacity: 0.2;
      }
    }
    .diseaseMinBox,
    .diseaseBox,
    .diseaseMaxBox {
      overflow: scroll;
      height: 16rem;
      padding-top: 40%;
      .diseaseUl {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding-bottom: 1rem;
        .diseaseList {
          width: 9.2rem;
          background: #ffffff;
          box-shadow: 0rem -0.03rem 0.4rem 0rem rgba(0, 0, 0, 0.03);
          border-radius: 0.27rem;
          margin: 0.4rem 0 0 0;
          padding: 0.4rem 0.35rem 0.4rem 0.4rem;
          .diseaseInfo {
            display: flex;
            margin-bottom: 0.13rem;
          }
          .illnessName {
            font-size: 0.45rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 0.53rem;
          }
          .dropDown {
            width: 0.56rem;
            height: 0.56rem;
            background: url("../../assets/images/dropDown.png") no-repeat;
            background-size: 100% 100%;
            margin-left: auto;
          }
          .heading {
            height: 1.07rem;
            font-size: 0.35rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 0.53rem;
          }
          .cc {
            background: red;
          }
        }
      }
    }
    .diseaseMinBox {
      padding-top: 34%;
    }
    .diseaseMaxBox {
      padding-top: 48%;
    }
  }
  // 模态框
  .prompt-box {
    padding: 0rem 0.53rem 0 0.4rem;
    overflow: scroll;
    .prompt-header {
      height: 1.47rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eeeeee;
      position: fixed;
      background: #fff;
      width: 90%;
      .prompt {
        height: 0.53rem;
        font-size: 0.37rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 0.53rem;
      }
      .close {
        width: 0.4rem;
        height: 0.4rem;
        background: url("../../assets/images/close.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .promptUl {
      width: 100%;
      margin-top: 1.47rem;
      padding-bottom: 2.77rem;
      .list {
        display: flex;
        align-items: center;
        padding: 0.4rem 0 0.37rem 0;
        border-bottom: 1px solid #eeeeee;
        .selected {
          .selectedImg {
            width: 0.53rem;
            height: 0.53rem;
            background: url("../../assets/images/selected.png") no-repeat;
            background-size: 100% 100%;
          }
          .noData {
            width: 0.53rem;
            height: 0.53rem;
            background: url("../../assets/images/emptyCircle.png") no-repeat;
            background-size: 100% 100%;
          }
        }
        .sickness {
          line-height: 0.56rem;
          font-size: 0.4rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #444444;
          flex: 1;
          margin-left: 0.29rem;
        }
      }
    }
    footer {
      width: 100%;
      height: 2.77rem;
      background: #ffffff;
      position: fixed;
      left: 0px;
      bottom: 0px;
      .determine,
      .noDetermine {
        width: 4.88rem;
        height: 1.01rem;
        background: #4c7fff;
        border-radius: 0.56rem;
        display: flex;
        justify-content: center;
        align-content: center;
        font-size: 0.43rem;
        font-family: AlibabaPuHuiTiR;
        color: #ffffff;
        line-height: 1.01rem;
        margin: 0.96rem auto 0.8rem auto;
      }
      .noDetermine {
        opacity: 0.2;
      }
    }
  }
}
</style>
